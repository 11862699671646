export enum CountryEnum {
  "Argentina" = "ARGENTINA",
  "Australia" = "AUSTRALIA",
  "Brazil" = "BRAZIL",
  "Canada" = "CANADA",
  "China" = "CHINA",
  "China - Hong Kong" = "CHINA_HONGKONG",
  "China - Taiwan" = "CHINA_TAIWAN",
  "Chile" = "CHILE",
  "Egypt" = "EGYPT",
  "England" = "ENGLAND",
  "Germany" = "GERMANY",
  "Greece" = "GREECE",
  "Indonesia" = "INDONESIA",
  "India" = "INDIA",
  "Japan" = "JAPAN",
  "Korea" = "KOREA",
  "Malaysia" = "MALAYSIA",
  "Mexico" = "MEXICO",
  "Myanmar" = "MYANMAR",
  "Netherland" = "NETHERLAND",
  "New Zealand" = "NEW_ZEALAND",
  "Peru" = "PERU",
  "Philippines" = "PHILIPPINES",
  "Russia" = "RUSSIA",
  "Singapore" = "SINGAPORE",
  "Spain" = "SPAIN",
  "Thailand" = "THAILAND",
  "Turkey" = "TURKEY",
  "UK" = "UK",
  "USA" = "USA",
  "Vietnam" = "VIETNAM",
}

export enum CurrencyEnum {
  USD = "USD",
  SGD = "SGD",
  MYR = "MYR",
  THB = "THB",
  CNY = "CNY",
  JPY = "JPY",
  KRW = "KRW",
  EUR = "EUR",
}

// need to update
export enum PackagingTypeEnum {
  "Bag" = "BAG",
  "Bar" = "BAR",
  "Box" = "BOX",
  "Bottle" = "BTL",
  "Can" = "CAN",
  "Cup" = "CUP",
  "Jar" = "JAR",
  "PCH" = "PCH",
  "Pack" = "PCK",
  "SET" = "SET",
  "TBE" = "TBE",
  "TRY" = "TRY",
  "PVC jar" = "PVC_JAR",
  "PET bottle" = "PET_BOTTLE",
  "PE bag" = "PE_BAG",
  "Glass jar" = "GLASS_JAR",
}

export enum WhStorageEnum {
  "Ambient storage" = "AMBIENT_STORAGE",
  "Dry storage" = "DRY_STORAGE",
  "Refrigerated storage" = "REFRIGERATED_STORAGE",
  "Frozen Food storage" = "FROZEN_FOOD_STORAGE",
  "Hazardous storage" = "HAZARDOUS_STORAGE",
  "Archive storage" = "ARCHIVE_STORAGE",
  "Bonded storage" = "BONDED_STORAGE",
}

export enum WeightUnitEnum {
  KG = "KG",
  G = "G",
  LB = "LB",
  OZ = "OZ",
  ML = "ML",
  L = "L",
}

export enum LengthUnitEnum {
  CM = "CM",
}
export enum ShelfLifeUnitEnum {
  DAY = "DAY",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export enum SellingPriceUomUnitEnum {
  ML = "ML",
  L = "L",
  G = "G",
  KG = "KG",
}

export enum NumberOfEmployeesEnum {
  FROM_1_TO_10 = "FROM_1_TO_10",
  FROM_10_TO_50 = "FROM_10_TO_50",
  FROM_50_TO_100 = "FROM_50_TO_100",
  MORE_THAN_100 = "MORE_THAN_100",
}

export enum AnnualSaleEnum {
  LOWER_THAN_1M = "LOWER_THAN_1M",
  FROM_1M_TO_5M = "FROM_1M_TO_5M",
  FROM_5M_TO_10M = "FROM_5M_TO_10M",
  MORE_THAN_10M = "MORE_THAN_10M",
}

export enum SupplierCountryCodeEnum {
  AR = "AR",
  AU = "AU",
  BR = "BR",
  CA = "CA",
  CN = "CN",
  HK = "HK",
  TW = "TW",
  CL = "CL",
  EG = "EG",
  DE = "DE",
  GR = "GR",
  ID = "ID",
  IN = "IN",
  JP = "JP",
  KR = "KR",
  MY = "MY",
  MX = "MX",
  MM = "MM",
  NZ = "NZ",
  PE = "PE",
  PH = "PH",
  RU = "RU",
  SG = "SG",
  ES = "ES",
  TH = "TH",
  TR = "TR",
  GB = "GB",
  US = "US",
  VN = "VN",
}

export enum SupplierDocumentCategoryEnum {
  CERTIFICATE = "CERTIFICATE",
  AWARD = "AWARD",
  CATALOG = "CATALOG",
  OTHER = "OTHER",
}

export enum SupplierCategory {
  FoodAndBeverage = "FOOD_AND_BEVERAGE",
  HomeAndLiving = "HOME_AND_LIVING",
}

export enum UserRole {
  OWNER = "OWNER",
  USER = "USER",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  NO_LOGIN = "NO_LOGIN",
}

export enum AttributeTypeEnum {
  TEXT = "TEXT",
  DATE = "DATE",
  DATETIME = "DATETIME",
  DROPDOWN = "DROPDOWN",
  NUMBER = "NUMBER",
  PRICE = "PRICE",
  URL = "URL",
  MEASURE = "MEASURE",
  PRICE_MEASURE = "PRICE_MEASURE",
}

export enum SupportedDateFormatsEnum {
  "DD/MM/YYYY" = "DD/MM/YYYY",
  "MM/DD/YYYY" = "MM/DD/YYYY",
  "YYYY/MM/DD" = "YYYY/MM/DD",
}

export enum SupportedTimezonesEnum {
  "UTC +01:00" = "Africa/Algiers",
  "UTC +02:00" = "Europe/Paris",
  "UTC +03:00" = "Europe/Moscow",
  "UTC +04:00" = "Asia/Dubai",
  "UTC +05:00" = "Asia/Karachi",
  "UTC +06:00" = "Asia/Dhaka",
  "UTC +07:00" = "Asia/Jakarta",
  "UTC +08:00" = "Asia/Kuala_Lumpur",
  "UTC +09:00" = "Asia/Tokyo",
  "UTC +10:00" = "Australia/Brisbane",
  "UTC +11:00" = "Asia/Magadan",
  "UTC +12:00" = "Pacific/Auckland",
  "UTC +13:00" = "Pacific/Apia",
  "UTC +14:00" = "Pacific/Kiritimati",
  "UTC -01:00" = "Atlantic/Cape_Verde",
  "UTC -02:00" = "America/Noronha",
  "UTC -03:00" = "America/Recife",
  "UTC -04:00" = "America/Toronto",
  "UTC -05:00" = "America/Winnipeg",
  "UTC -06:00" = "America/Guatemala",
  "UTC -07:00" = "America/Los_Angeles",
  "UTC -08:00" = "America/Anchorage",
  "UTC -09:00" = "Pacific/Gambier",
  "UTC -10:00" = "Pacific/Honolulu",
  "UTC -11:00" = "Pacific/Pago_Pago",
}

export enum EnvEnum {
  DEV = "dev",
  QA = "qa",
  STAGING = "staging",
  PRODUCTION = "prod",
}

export const excludeAttributeTypeFromOptions = {
  [AttributeTypeEnum.PRICE]: true,
  [AttributeTypeEnum.MEASURE]: true,
  [AttributeTypeEnum.PRICE_MEASURE]: true,
};
