/* eslint-disable no-console */
import fetch from "isomorphic-unfetch";
import { GetServerSidePropsContext, NextPageContext } from "next";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { setAuthCookies } from "@utils/auth";
import { getCookie } from "@utils/cookie";
import { isBrowser, isServer } from "@utils/environments";
import { getLocalStorageValue } from "@utils/localStorage";

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($input: AdminRefreshTokenInput!) {
    adminRefreshToken(input: $input) {
      refreshToken
      accessToken
      expiresIn
      user {
        status
      }
    }
  }
`;

export const needRefreshToken = (
  token: string,
  ctx?: NextPageContext | GetServerSidePropsContext
): boolean => {
  const expirationDate = getCookie("expirationDate", ctx);
  const refreshToken = getCookie("refreshToken", ctx);
  const isExpired = Number(expirationDate) <= new Date().getTime();

  // If the expirationDate does not exist, indicates that this is a token before the release. The token is valid for 30 days and can still be accessed by users
  // If the expirationDate exists, indicates that the process is new and refreshToken can be called
  return Boolean(
    (expirationDate && (!token || isExpired)) || (refreshToken && !token)
  );
};

class ApolloClientFactory {
  private readonly singleClient: ApolloClient<object> | undefined;

  private static token: string;

  private static ctx: NextPageContext | GetServerSidePropsContext | undefined;

  // when server side need to send in token
  constructor(ctx?: NextPageContext | GetServerSidePropsContext) {
    if (this.singleClient) {
      return;
    }

    ApolloClientFactory.token = getCookie("auth", ctx) || "";
    ApolloClientFactory.ctx = ctx;

    if (isBrowser() && ApolloClientFactory.token === "")
      ApolloClientFactory.token =
        getCookie("auth") || ApolloClientFactory.token;

    const backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL;

    if (!backendUrl || backendUrl.length === 0)
      throw Error("BACKEND_URL is not set correctly");

    const httpLink = new HttpLink({ uri: backendUrl, fetch });

    const authLink = setContext(async (request) => {
      let refreshResult;
      // if the current request is RefreshToken, do not need token
      if (
        request.operationName !== "RefreshToken" &&
        needRefreshToken(ApolloClientFactory.token, ApolloClientFactory.ctx)
      ) {
        // eslint-disable-next-line no-use-before-define
        refreshResult = await refreshTokenRequest(ctx);
      }
      // return the headers to the context
      return {
        headers: {
          Authorization:
            refreshResult?.auth ||
            getCookie("auth") ||
            ApolloClientFactory.token,
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          // client
          if (extensions && extensions.code === "401000" && isBrowser()) {
            window.location.assign("/logout?expired");
          }
          // server
          if (ctx?.res) {
            ctx.res.writeHead(303, { Location: "/logout?expired" });
            ctx.res.end();
          }

          if (process.env.NODE_ENV === "development")
            console.error(
              `[GraphQL error]: extensions:${JSON.stringify(
                extensions
              )}, Message: ${message}, Location: ${JSON.stringify(
                locations
              )}, Path: ${path}`
            );
        });
      }
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    });

    // this middleware will only modify operation variables that are sent to the server, not responses from querying data. It allows us to not have to handle __Typename key that will break mutations.
    const clearTypeName = new ApolloLink((operation, forward) => {
      if (operation.variables) {
        const omitTypename = (key: string, value: string) =>
          key === "__typename" ? undefined : value;
        operation.variables = JSON.parse(
          JSON.stringify(operation.variables),
          omitTypename
        );
      }
      return forward(operation);
    });

    this.singleClient = new ApolloClient({
      ssrMode: isServer(), // Enable SSR mode if in the server
      link: ApolloLink.from([clearTypeName, errorLink, authLink, httpLink]),
      cache: new InMemoryCache(),
      connectToDevTools: true,
    });
  }

  get client(): ApolloClient<object> {
    if (this.singleClient) {
      return this.singleClient;
    }
    throw Error("initial error");
  }
}

export default ApolloClientFactory;

export const refreshTokenRequest = async (
  ctx?: NextPageContext | GetServerSidePropsContext
) => {
  const factory = new ApolloClientFactory(ctx);
  const refreshToken = getCookie("refreshToken", ctx);
  try {
    const { data } = await factory.client.mutate({
      mutation: REFRESH_TOKEN,
      variables: {
        input: {
          refreshToken,
        },
      },
    });

    if (isBrowser()) {
      setAuthCookies(
        {
          expiresIn: Number(data.adminRefreshToken.expiresIn),
          accessToken: data.adminRefreshToken.accessToken,
          refreshToken: data.adminRefreshToken.refreshToken,
        },
        getLocalStorageValue("remember") === "true"
      );
    }
    return {
      auth: data.adminRefreshToken.accessToken,
      refreshToken: data.adminRefreshToken.refreshToken,
    };
  } catch (error) {
    console.error("GraphQL error:", error);
    return null;
  }
};
