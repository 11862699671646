import React, { createContext, useMemo, useState } from "react";
import { EnvEnum } from "@constants/enum";
import { FeatureToggle } from "@featureFlags/featureFlags";
import { AuthInfoType } from "@utils/auth";
import { isBrowser } from "@utils/environments";

export interface AppContextType {
  authInfo?: AuthInfoType;
  featureFlags?: Record<FeatureToggle, boolean>;
  env: EnvEnum;
}

interface AppContextProps {
  ctx: AppContextType | null;
  setContext: (ctx: AppContextType) => void;
}

export const AppContext = createContext<AppContextProps>({
  ctx: null,
  setContext: () => {
    console.error("provider is not initialed");
  },
});

export interface AppProviderProps {
  appContext: AppContextType;
  children: React.ReactNode;
}

type AppWindowType = typeof window & { __ENV__: AppContextType };

const AppProvider: React.FC<AppProviderProps> = ({
  appContext,
  children,
}: AppProviderProps) => {
  const [ctx, setContext] = useState<AppContextType | null>(appContext);
  const contextVal = useMemo(() => ({ ctx, setContext }), []);
  if (isBrowser()) {
    // eslint-disable-next-line no-underscore-dangle
    (window as AppWindowType).__ENV__ = appContext;
  }
  return (
    <AppContext.Provider value={contextVal}>{children}</AppContext.Provider>
  );
};

export default AppProvider;
