import { ReactElement } from "react";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import Head from "next/head";
import { EnvEnum } from "@constants/enum";
import { AuthInfoType, getAuthInfo } from "@utils/auth";
import { getEnv } from "@utils/environments";
import { FeatureToggle, getFeatureToggleMapping } from "@utils/featureToggle";
import "../src/styles/globals.scss";
import Providers from "./Providers";

interface PIMAppProps extends AppProps {
  customProp: {
    authInfo?: AuthInfoType;
    featureFlags?: Record<FeatureToggle, boolean>;
    env: EnvEnum;
  };
}

class PIMApp extends App<PIMAppProps> {
  static async getInitialProps({
    Component,
    ctx,
    router,
  }: AppContext): Promise<PIMAppProps> {
    const authInfo = getAuthInfo(ctx);
    const env = getEnv();
    const featureFlags = getFeatureToggleMapping(ctx, { env });
    const customProp = {
      authInfo,
      featureFlags,
      env,
    };
    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return {
      customProp,
      pageProps,
      Component,
      router,
    };
  }

  render(): ReactElement {
    const { Component, pageProps, customProp } = this.props;

    return (
      <>
        <Head>
          <title>Trustana Admin Portal</title>
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <Providers
          appContext={{
            authInfo: customProp.authInfo,
            featureFlags: customProp.featureFlags,
            env: customProp.env,
          }}
        >
          <Component {...pageProps} customProp={customProp} />
        </Providers>
      </>
    );
  }
}

export default PIMApp;
