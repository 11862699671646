import { GetServerSidePropsContext, NextPageContext } from "next/types";
import nookies from "nookies";

export const findCommaSeparatedValue = (
  rawString: string,
  name: string
): string | undefined => {
  const regex = new RegExp(`(?:^|;)\\s*${name}\\s*=\\s*([^;]+)`);
  const matches = regex.exec(rawString);
  return matches ? matches[1] : undefined;
};

export const getCookie = (
  name: string,
  ctx?: NextPageContext | GetServerSidePropsContext | null
): string | undefined => {
  const cookies = nookies.get(ctx);
  const value = cookies[name];
  return value || undefined;
};

// define it to avoid adding cookie package
export interface CookieSerializeOptions {
  domain?: string | undefined;
  encode?(value: string): string;
  expires?: Date | undefined;
  httpOnly?: boolean | undefined;
  maxAge?: number | undefined;
  path?: string | undefined;
  priority?: "low" | "medium" | "high" | undefined;
  sameSite?: true | false | "lax" | "strict" | "none" | undefined;
  secure?: boolean | undefined;
}

export const setCookie = (
  cookieName: string,
  cookieValue: string,
  options?: CookieSerializeOptions,
  ctx?: NextPageContext | GetServerSidePropsContext
) => {
  const cookieOptions = {
    path: "/",
    sameSite: true,
    secure: true,
    httpOnly: true,
    ...options,
  };
  nookies.set(ctx, cookieName, cookieValue, cookieOptions);
};
