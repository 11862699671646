import accountManagement from "public/locales/en/accountManagement.json";
import attributeConfiguration from "public/locales/en/attributeConfiguration.json";
import auth from "public/locales/en/auth.json";
import common from "public/locales/en/common.json";
import global from "public/locales/en/global.json";
import product from "public/locales/en/product.json";
import productEdit from "public/locales/en/productEdit.json";
import productView from "public/locales/en/productView.json";
import suppliers from "public/locales/en/suppliers.json";
import templateUpload from "public/locales/en/templateUpload.json";
import uploadTool from "public/locales/en/uploadTool.json";

export default {
  common,
  product,
  productEdit,
  productView,
  templateUpload,
  global,
  accountManagement,
  uploadTool,
  attributeConfiguration,
  suppliers,
  auth,
};
