import { isBrowser } from "@utils/environments";

export const getLocalStorageValue = (key: string) => {
  if (!isBrowser()) {
    return null;
  }
  try {
    const item = localStorage.getItem(key);

    if (typeof item === "string" && (item[0] === "{" || item[0] === "[")) {
      return JSON.parse(item);
    }

    return item;
  } catch (e: unknown) {
    throw Error(`get localstorage value error:${JSON.stringify(e)}`);
  }
  return null;
};

export const setLocalStorageValue = (
  key: string,
  value: object | string
): boolean => {
  if (!isBrowser() || typeof value === "function") {
    return false;
  }
  try {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }

    return true;
  } catch (e: unknown) {
    throw Error(`set localstorage value error:${JSON.stringify(e)}`);
  }
  return false;
};

export const removeLocalStorageValue = (key: string) => {
  localStorage.removeItem(key);
};
