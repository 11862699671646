import { EnvEnum } from "@constants/enum";

export type FeatureToggleEnvType = Record<EnvEnum, boolean>;
export type FeatureToggleType = boolean | FeatureToggleEnvType;

export enum FeatureToggle {
  FeatureToggleDemo = "feature-toggle-demo",
  ProductEnrichment = "product-enrichment",
}

export const featureToggleConfig: Record<FeatureToggle, FeatureToggleType> = {
  [FeatureToggle.FeatureToggleDemo]: false,
  [FeatureToggle.ProductEnrichment]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: false,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: false,
  },
};
