import jwt from "jwt-decode";
import type { GetServerSidePropsContext, NextPageContext } from "next";
import { getCookie, setCookie } from "@utils/cookie";

export function parseJwt(token: string) {
  return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
}

export type UserType = "ADMIN_USER" | "SUPPLIER_USER";

export interface AuthInfoType {
  userId: number;
  email: string;
  userType: UserType;
}

export const getAuthInfo = (
  ctx: NextPageContext | GetServerSidePropsContext
): AuthInfoType | undefined => {
  if (!ctx.req?.headers.cookie) {
    return undefined;
  }
  const authStr = getCookie("auth", ctx);
  if (!authStr) {
    return undefined;
  }

  const userInfo = jwt(authStr.replace("Bearer", "")) as AuthInfoType;

  return userInfo;
};

export interface LoginResultType {
  expiresIn: number;
  accessToken: string;
  refreshToken: string;
}
export const setAuthCookies = (data: LoginResultType, remember?: boolean) => {
  const expirationDate =
    new Date().getTime() + Number(data.expiresIn * 1000) - 20 * 1000; // minus 20s to avoid network delay

  setCookie("auth", data.accessToken, {
    maxAge: 1 * 24 * 60 * 60,
    httpOnly: false,
  });
  setCookie("refreshToken", data.refreshToken, {
    maxAge: remember ? 30 * 24 * 60 * 60 : 1 * 24 * 60 * 60,
    httpOnly: false,
  });
  setCookie("expirationDate", String(expirationDate), {
    maxAge: 30 * 24 * 60 * 60,
    httpOnly: false,
  });
};

export const logout = (ctx: NextPageContext | GetServerSidePropsContext) => {
  // remove cookies
  setCookie(
    "auth",
    "",
    {
      maxAge: 0,
    },
    ctx
  );
  setCookie(
    "refreshToken",
    "",
    {
      maxAge: 0,
    },
    ctx
  );
  setCookie(
    "expirationDate",
    "",
    {
      maxAge: 0,
    },
    ctx
  );
  // remove localStorage if needed
};
