import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import en from "./locales/en";

export enum Namespace {
  GLOBAL = "global",
  COMMON = "common",
  PRODUCT = "product",
  PRODUCT_EDIT = "productEdit",
  PRODUCT_VIEW = "productView",
  PRODUCT_UPLOAD = "templateUpload",
  ACCOUNT_MANAGEMENT = "accountManagement",
  SUPPLIERS = "suppliers",
  UPLOAD_TOOL = "uploadTool",
  ATTRIBUTE_CONFIGURATION = "attributeConfiguration",
  AUTH = "auth",
}

export enum SupportedLangs {
  EN = "en",
}

const ns = Object.values(Namespace);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en,
    },
    fallbackLng: SupportedLangs.EN,
    ns,
    defaultNS: Namespace.COMMON,
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
  });

export default i18n;
